import qs from 'qs'

import getOrCreateStore from 'store/getOrCreateStore'

import { pushDataLayer } from 'helpers/googleAnalytics'
import { isServer } from 'helpers/utilities'
import { findAreaByMapboxId } from 'helpers/mapbox'

const defaultTimeout = 1000
let filterTimeout = null

export const applyFilters = (router) => {
  if (!router) { return }

  const url = getFilterUrl({ page: 1 })

  router.push(url)
}

export const applyFiltersWithTimeout = (router, timeout = defaultTimeout) => {
  window.clearTimeout(filterTimeout)

  filterTimeout = window.setTimeout(() => {
    applyFilters(router)
  }, timeout)
}

export const getFilterUrl = ({
  pathname = '/providers',
  currentFilters = getOrCreateStore().getState().providersFilter.filters,
  page = getOrCreateStore().getState().providers.page,
  providerType = getOrCreateStore().getState().providers.providerType,
  filter = {},
  parameters = {},
  countryName = getOrCreateStore().getState().headerData?.countryName,
}) => {
  const newFilters = {
    ...currentFilters,
    ...filter,
  }

  let newBounds
  const directionsIncluded = ['north', 'east', 'south', 'west'].every(direction => newFilters.bounds[direction])

  if (newFilters.bounds && directionsIncluded) {
    newBounds = newFilters.bounds
  }

  const isPriceFilterEnabled = ['austria', 'germany'].includes(countryName) || providerType !== 'event_location'

  const searchRequest = {
    event_category_ids: newFilters.occasionFilter ? [newFilters.occasionFilter] : null,
    booking_date: newFilters.bookingDate || undefined,
    end_time: newFilters.endTime || undefined,
    guest_count: newFilters.guestCount || undefined,
    seating_plans: newFilters.seatingPlans || undefined,
    min_price: (isPriceFilterEnabled && newFilters.minPrice) || undefined,
    max_price: (isPriceFilterEnabled && newFilters.maxPrice) || undefined,
    location_trait_ids: newFilters.locationTraits || undefined,
    property_ids: [
      ...newFilters.technicalEquippings,
      ...newFilters.amenities,
      newFilters.supplierType,
    ].filter(Boolean),
    location_type_ids: newFilters.locationTypes || undefined,
    room_size: newFilters.roomSize || undefined,
    start_time: newFilters.startTime || undefined,
    hybrid: newFilters.hybrid || undefined,
    catering_option_ids: newFilters.cateringOptions || undefined,
    mapboxId: newFilters.mapboxId,
    bounds: newBounds,
    rooms: newFilters.rooms || undefined,
    hotel_rooms: newFilters.hotelRooms || undefined,
    hotel_type_ids: newFilters.hotelTypes || undefined,
    visibility_ids: newFilters.visibility || undefined,
    radius: newFilters.radius || undefined,
  }

  let filterUrl = pathname
  const params = {
    section: newFilters.section,
    type: newFilters.type || providerType,
    city: newFilters.cityFilter || null,
    projectId: newFilters.projectId || undefined,
    search_request: searchRequest,
    ...parameters,
  }

  if (page && page !== 1) {
    params.page = page
  }

  const queryString = qs.stringify(params, {
    arrayFormat: 'brackets',
    skipNulls: true,
  })

  if (queryString) {
    filterUrl += `?${queryString}`
  }

  if (!isServer()) {
    filterUrl += window.location.hash
  }

  return filterUrl
}

export const trackFilterUsage = (filters) => {
  for (let name in filters) {
    let filterValue = filters[name]

    if (
      !filterValue ||
      filterValue.length === 0 ||
      (
        typeof filterValue === 'object' &&
        Object.values(filterValue).filter(Boolean).length === 0
      )
    ) {
      continue
    }

    if (Array.isArray(filterValue)) {
      filterValue = `[${filterValue.join(', ')}]`
    } else if (typeof filterValue === 'object') {
      filterValue = Object.values(filterValue).join(', ')
    }

    if (name === 'mapboxId') {
      filterValue = findAreaByMapboxId(filterValue, getOrCreateStore().getState())?.text
      name = 'Geoloactionsearch'
    }

    pushDataLayer({
      event: 'googleAnalyticsGenericEvent',
      eventCategory: 'Filter',
      eventAction: name,
      eventLabel: filterValue,
    })
  }
}
